.header {
  box-shadow: none;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: var(--white);
}

.main {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: var(--page-padding-y);

  /* padding: var(--page-padding-y) var(--page-padding-x); */
}
